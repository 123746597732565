html,
body,
.main-body,
.background-bz {
    height: 100%;
}

.loaderSvg {
    height: 8px;
}

.nav-tabs {
    margin-bottom: 1rem;
}

.vertical-offset-100 {
    padding-top: 100px;
}

.vertical-offset-20 {
    padding-top: 20px;
}

.main-body {
    min-height: 100%;
}

.background-bz {
    background: #56ccf2; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2f80ed, #56ccf2); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #2f80ed,
        #56ccf2
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100%;
}

.user-form {
    margin-top: 20px;
}

.nav-item,
.nav-link {
    cursor: pointer;
}

.nav-link a {
    cursor: pointer;
}

body {
    font-family: Helvetica, sans-serif;
}
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    /*width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-appearance: none;*/
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input::-ms-clear {
    display: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
    border-top: 0;
}

.react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: #777;
}

.highlight {
    color: #5cb3fd;
    font-weight: bold;
}

/**
* Theme custom CSS
**/

body {
    padding: 0px;
}

.menu-top-w .menu-top-i .logo-w img {
    width: 150px;
}

.users-list-w {
    width: 100%;
}

.users-list-w .user-w .user-avatar-w .user-avatar img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.team-banner {
    background-color: #21d4fd;
    background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
}

.user-profile .up-main-info {
    padding: 5% 5% 5% 5%;
}

.littleNumbers .el-tablo .value {
    font-size: 1rem;
}

.content-box {
    padding-top: 0rem;
}

.interval-switcher {
    margin-bottom: 1rem;
}
